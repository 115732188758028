import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Img from 'gatsby-image'

const Tile = ({ to, title, fluid, metaData }) => (
  <Link className="text-xl font-semibold hover:text-teal-500 transition-colors duration-200 tile" to={to}>
    <Img className="overflow-hidden rounded" fluid={fluid} />
    <div className="flex justify-between">
      {metaData.map((data) => {
        return <h3 key={data} className="text-gray-500 uppercase text-sm mt-4">{data}</h3>
      })}
    </div>
    { title && <h2 className="mt-4">{title}</h2> }
  </Link>
)

Tile.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  fluid: PropTypes.object,
  optionalMetaData: PropTypes.array
}

Tile.defaultProps = {
  metaData: []
}

export default Tile
