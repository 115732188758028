import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import Tile from '../components/tile'

const PhotographyPage = ({ data }) => (
  <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
    <SEO title="Photography" />
    {data.photos.edges.map(({ node }) => {
      return (
        <Tile
          key={node.id}
          to={`/photography/${node.fields.slug}`}
          fluid={node.image.localFile.childImageSharp.fluid}
        />
      )
    })}
  </div>
)

export const query = graphql`
  query PhotographyPageQuery {
    photos: allContentfulPhoto {
      edges {
        node {
          id
          title
          fields {
            slug
          }
          image {
            id
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 500, cropFocus: ENTROPY) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PhotographyPage
